import React, { Fragment, useState, useEffect, useCallback } from "react";
import Path from "path";
import { useAuth, useCollection, useDoc } from "./hooks/useFirebase";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import Login from "./components/Login";
import Book from "./components/Book";

export default function App() {
  const { user } = useAuth();

  const { data: books, update: updateBook } = useCollection("ebooks");
  // let { bookId } = useParams();

  return (
    <Router>
      <div className="px-4 sm:px-6 lg:px-8 mt-4 mb-4">
        <Login />
        <div className="h-6"></div>
        <Switch>
          <Route path="/books/:bookId">
            <Book />
          </Route>
          <Route path="/">
            {books && (
              <ul>
                {books.map((book) => (
                  <li key={book.id}>
                    <Link className="hover:underline" to={`/books/${book.id}`}>
                      {book.metadata?.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
