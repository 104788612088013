const get = require("lodash.get");
const memoize = require("lodash.memoize");
const without = require("lodash.without");

export const inOrder = memoize(
  function ({ children, expansions, prefix = "" }) {
    // console.log("RUNNING inOrder");
    let paths = [];
    children.forEach((node, i) => {
      const path = `${prefix}${i}`;
      paths.push(path);

      if (expansions.includes(path)) {
        if (node.children)
          paths = [
            ...paths,
            ...inOrder({
              children: node.children,
              expansions,
              prefix: path + ".",
            }),
          ];
      }
    });
    return paths;
  },
  function resolver({ expansions, prefix }) {
    // NOTE: the cache ignores changes to children, which should be fine? it might fuck up if you load a different book or something...
    return JSON.stringify({ expansions, prefix });
  }
);

export function move({ path, nodes, expansions, direction }) {
  let pathsInOrder = inOrder({ children: nodes.children, expansions });

  if (path === null) {
    if (direction === "down" || direction === "right" || direction === "next")
      return { path: "0", expansions };
    if (direction === "up" || direction === "left" || direction === "prev")
      return {
        path: pathsInOrder[pathsInOrder.length - 1],
        expansions,
      };
    // direction isn't up, down, left, right, next, or prev. wtf. do nothing I guess.
    return { path, expansions };
  }

  let pathIndex = pathsInOrder.indexOf(path);
  let pathParts = path.split(".");
  const getPath = "children." + pathParts.join(".children.");

  if (direction === "down") {
    pathIndex++;
    if (pathIndex >= pathsInOrder.length) pathIndex = pathsInOrder.length;
    return { path: pathsInOrder[pathIndex], expansions };
  } else if (direction === "up") {
    pathIndex--;
    if (pathIndex < 0) pathIndex = 0;
    return { path: pathsInOrder[pathIndex], expansions };
  } else if (direction === "left") {
    if (expansions.includes(path)) expansions = without(expansions, path);
    else if (pathParts.length > 1)
      pathParts = pathParts.slice(0, pathParts.length - 1);
    return { path: pathParts.join("."), expansions };
  } else if (direction === "right") {
    // get the node
    const node = get(nodes, getPath);
    // console.log({ getPath, node, nodes });
    // let newExpansions = expansions;
    // don't expand if this node can't be expanded
    if ((node.children || node.footnotes) && !expansions.includes(path))
      return { path, expansions: [...expansions, path] };
  } else if (direction === "next") {
    const node = get(nodes, getPath);
    // const newExpansions = [...expansions]
    // if this node has children and isn't expanded,
    // expand it and recalculate pathsInOrder

    if (node.children && !expansions.includes(path)) {
      expansions.push(path);
      pathsInOrder = inOrder({
        children: nodes.children,
        expansions,
      });
    }

    pathIndex++;
    if (pathIndex >= pathsInOrder.length) pathIndex = pathsInOrder.length;
    return { path: pathsInOrder[pathIndex], expansions };
  } else if (direction === "prev") {
    // prev
  }

  return { path, expansions };
}

export function up({
  activeNodePath,
  setActiveNodePath,
  nodes,
  expansions,
  setExpansions,
}) {
  const { path, expansions: newExpansions } = move({
    path: activeNodePath,
    nodes,
    expansions,
    direction: "up",
  });

  setActiveNodePath(path);
  // setExpansions(newExpansions);
}

export function down({
  activeNodePath,
  setActiveNodePath,
  nodes,
  expansions,
  setExpansions,
}) {
  const { path, expansions: newExpansions } = move({
    path: activeNodePath,
    nodes,
    expansions,
    direction: "down",
  });

  setActiveNodePath(path);
  // setExpansions(newExpansions);
}

export function left({
  activeNodePath,
  setActiveNodePath,
  nodes,
  expansions,
  setExpansions,
}) {
  const { path, expansions: newExpansions } = move({
    path: activeNodePath,
    nodes,
    expansions,
    direction: "left",
  });

  setActiveNodePath(path);
  setExpansions(newExpansions);
}

export function right({
  activeNodePath,
  setActiveNodePath,
  nodes,
  expansions,
  setExpansions,
}) {
  const { path, expansions: newExpansions } = move({
    path: activeNodePath,
    nodes,
    expansions,
    direction: "right",
  });

  setActiveNodePath(path);
  setExpansions(newExpansions);
}

export function next({
  activeNodePath,
  setActiveNodePath,
  nodes,
  expansions,
  setExpansions,
}) {
  const { path, expansions: newExpansions } = move({
    path: activeNodePath,
    nodes,
    expansions,
    direction: "next",
  });

  setActiveNodePath(path);
  setExpansions(newExpansions);
}

// exports.inOrder = inOrder;
// exports.move = move;
// exports.up = up;
// exports.down = down;
// exports.left = left;
// exports.right = right;
// exports.next = next;

// export default { inOrder, move, up, down, left, right, next };
