import { useEffect } from "react";
import { useImmer } from "use-immer";
import throttle from "lodash.throttle";

const debouncedSetLocalstorage = throttle((k, v) => {
  // console.log("setting local storage");
  // console.log({ k, v });
  localStorage.setItem(k, JSON.stringify(v));
}, 1000);

const useStateWithLocalstorage = (key, defaultValue = null) => {
  // console.log({ key, defaultValue, lc: localStorage.getItem(key) });
  const initialValue =
    JSON.parse(localStorage.getItem(key) || "null") || defaultValue;

  const [value, setValue] = useImmer(initialValue);

  const indifferentSetValue = (value) => {
    if (typeof value === "function") setValue(value);
    else setValue(() => value);
  };

  useEffect(() => {
    debouncedSetLocalstorage(key, value);
  }, [key, value]);

  return [value, indifferentSetValue];
};

export default useStateWithLocalstorage;
