import React, { Fragment } from "react";
import { useAuth } from "../hooks/useFirebase";

export default function Login({}) {
  const { user, signOut, signinWithGoogle } = useAuth();

  return (
    <div className="absolute top-0 right-0 mt-2 mr-2">
      {!user && (
        <button
          className="bg-blue-400 text-white rounded px-1 py-1"
          onClick={signinWithGoogle}
        >
          Sign in
        </button>
      )}
      {user && (
        <Fragment>
          {user.displayName}{" "}
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white rounded px-1 py-1"
            onClick={signOut}
          >
            Sign out
          </button>
        </Fragment>
      )}
    </div>
  );
}
