import React, { useState, useEffect } from "react";

// yarn add firebase react-firebase-hooks lodash.debounce lodash.mapvalues

// import Path from "path";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";
// import "firebase/analytics";
import {
  useDocumentData,
} from "react-firebase-hooks/firestore";

import debounce from "lodash.debounce";
import mapValues from "lodash.mapvalues";

const useCollectionHook = require("react-firebase-hooks/firestore").useCollection;

const FirebaseContext = React.createContext();
const FirestoreContext = React.createContext();
const FirebaseUserContext = React.createContext();

function storeUser({ user, db }) {
  if (user) {
    const { uid, displayName, photoURL, email, isAnonymous } = user;
    const { creationTime, lastSignInTime } = user.metadata;

    const userDoc = db.collection("users").doc(user.uid);
    const userData = {
      uid,
      displayName,
      photoURL,
      email,
      isAnonymous,
      creationTime: new Date(creationTime),
      lastSignInTime: new Date(lastSignInTime),
    };
    userDoc.set(userData, { merge: true });
    return { userDoc, userData };
  }
  return {};
}

function FirebaseProvider({ children, config, enablePersistence }) {
  const [user, setUser] = useState(null);

  if (!firebase.apps.length) {
    firebase.initializeApp(config);

    // are we on the client?
    // if(typeof window !== "undefined"){
    //   const performance = firebase.performance();
    // }
    // if(process.browser){
      // const analytics = firebase.analytics();
    // }

    if (enablePersistence) {
      firebase
        .firestore()
        .enablePersistence({ synchronizeTabs: true })
        .catch(err => {
          if (err.code === "failed-precondition") {
            alert(
              "Oh yikes—looks like multiple tabs are open. Offline support may be weird."
            );
          } else if (err.code === "unimplemented") {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
          }
        });
    }
  }
  // const firebaseApp = !firebase.apps.length
  //   ?
  //   : firebase.app();

  // firebase.analytics();
  const db = firebase.firestore();
  // window.db = db

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      // userDoc
      const { userData } = storeUser({ user, db });
      setUser(userData || null);
    });
  }, [ db ]);

  return (
    <FirebaseContext.Provider value={firebase}>
      <FirebaseUserContext.Provider value={user}>
        <FirestoreContext.Provider value={db}>
          {children}
        </FirestoreContext.Provider>
      </FirebaseUserContext.Provider>
    </FirebaseContext.Provider>
  );
}

function useFirestore() {
  const context = React.useContext(FirestoreContext);
  if (context === undefined) {
    throw new Error("useFirestore must be used within a FirebaseProvider");
  }
  return context;
}

function useFirebase() {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("useFirebase must be used within a FirebaseProvider");
  }
  return context;
}

function useStorage() {
  const firebase = useFirebase();
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);

  function upload({ data, path, metadata = {}, onProgress }) {
    const ref = firebase.storage().ref().child(path);
    const uploadTask = ref.put(data, metadata);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        snapshot => {
          if (typeof onProgress === "function") {
            const total = snapshot.totalBytes;
            const transferred = snapshot.bytesTransferred;
            const percent = (transferred / total) * 100;
            onProgress({ total, transferred, percent });
          }
          // switch (snapshot.state) {
          //   case firebase.storage.TaskState.PAUSED:
          //     console.log("Upload is paused");
          //     break;
          //   case firebase.storage.TaskState.RUNNING:
          //     console.log("Upload is running");
          //     break;
          // }
        },
        function (error) {
          reject(error);
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(url => {
            resolve({ url, ref: uploadTask.snapshot.ref });
            //   console.log("File available at", downloadURL);
          });
        }
      );
    });
  }

  return { upload, progress, error };
}

function signinWithGoogle({ scopes }) {
  const provider = new firebase.auth.GoogleAuthProvider();
  // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  // provider.addScope("https://www.googleapis.com/auth/youtube.upload");
  // provider.setCustomParameters({
  //   'login_hint': 'user@example.com'
  // });x
  if (scopes) scopes.forEach(scope => provider.addScope(scope));

  return firebase.auth().signInWithPopup(provider);
}

function useAuth() {
  const context = React.useContext(FirebaseUserContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a FirebaseProvider");
  }

  // link anonymous user with logged in user
  // https://firebase.google.com/docs/auth/web/anonymous-auth#convert-an-anonymous-account-to-a-permanent-account
  // firebase.auth().signInAnonymously()
  // error and loading?
  return {
    user: context,
    signOut: () => {
      // console.log("signing out");
      firebase.auth().signOut();
    },
    signinWithGoogle,
  };
}

function useIdToken(){
  const firebase = useFirebase();
  const {user} = useAuth();
  const [token, setToken] = useState(null);
  useEffect(() => {
    if(!firebase.auth().currentUser) return;
    async function fetchToken(){
      const _token = await firebase.auth().currentUser.getIdToken(true);
      setToken(_token);
    }
    fetchToken();
  }, [user, firebase]);
  return token;
}

const debouncedUpdate = debounce(({ doc, data }) => {
  // doc.update(data);
  // upsert
  doc.set(data, { merge: true })
}, 1000);

function mapDates(doc){
  return Object.fromEntries(Object.entries(doc).map(([k, v]) => {
    const value = typeof v?.toDate === "function" ? v.toDate() : v;
    return [k, value]
  }))
}

function useCollection(collectionPath, options = {}) {
  let path = collectionPath;
  const db = useFirestore();
  // window.db = db;
  const firebase = useFirebase();
  const { user } = useAuth();
  if (user && path[0] !== "/" && !options.group) path = `/users/${user.uid}/${path}`;

  const collection = options.group ? db.collectionGroup(path) : db.collection(path);
  let collectionQuery = collection;
  if (options.orderBy)
    collectionQuery = collectionQuery.orderBy(
      options.orderBy,
      options.desc ? "desc" : "asc"
    );

  if(options.where){
    // where can be an array of arrays or just an array
    let whereClauses = options.where;
    if(!Array.isArray(options.where[0])) whereClauses = [options.where];
    whereClauses.forEach(([a,b,c]) => {
      collectionQuery = collectionQuery.where(a,b,c);
    })
  }

  if (options.limit) {
    collectionQuery = collectionQuery.limit(options.limit);
  }

  const [snap, loading, error] = useCollectionHook(collectionQuery);
  let data = null;
  if (snap?.docs)
    data = snap.docs.map(doc => {
      return { ...mapDates(doc.data()), path: doc.ref.path, id: doc.id };
    });

  function add(doc) {
    return collection.add({
      ...doc,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  function update(doc) {
    // nested object updates use dot notation:
    // https://firebase.google.com/docs/firestore/manage-data/add-data#update_fields_in_nested_objects
    // undefined => null
    const updatedFields = mapValues(doc, v => v || null);
    const id = doc.id;
    delete updatedFields.id;
    delete updatedFields.path;
    return collection.doc(id).set({
      ...updatedFields,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true });
  }

  function remove(doc) {
    return collection.doc(doc.id).delete();
  }

  // console.log({ path });
  // return [firebaseData, addDoc, loading, error];
  return { data, add, update, remove, loading, error };
}

function useDoc(docPath) {
  let path = docPath; // || "/null/null";
  const db = useFirestore();
  const firebase = useFirebase();
  const { user } = useAuth();
  if (user && path && path[0] !== "/") path = `/users/${user.uid}/${path}`;

  if(!path) path = "/"

  const doc = db.doc(path);
  const [data, setData] = useState(null);

  const [firebaseData, loading, error] = useDocumentData(doc, {
    idField: "id",
  });

  // update local data if remote data changes
  useEffect(() => {
    setData(firebaseData ? { ...mapDates(firebaseData), path } : null);
  }, [firebaseData, path]);

  function setDataWithFirebase(newData) {
    setData({ ...data, ...newData });
    debouncedUpdate({
      doc,
      data: {
        ...newData,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
    });
  }

  function remove() {
    doc.delete();
  }

  function update(newData) {
    doc.update(newData);
  }

  function upsert(newData) {
    doc.set(newData, { merge: true });
  }

  return {
    data,
    update,
    upsert,
    debouncedUpdate: setDataWithFirebase,
    remove,
    loading,
    error,
  };
}

export {
  FirebaseProvider,
  useFirestore,
  useFirebase,
  useAuth,
  useIdToken,
  useCollection,
  useDoc,
  useStorage,
};
