import React, { useState, useEffect, Fragment, useRef } from "react";
import without from "lodash.without";
import ReactMarkdown from "react-markdown";

import useMousetrap from "hooks/useMousetrap";
import useStateWithLocalstorage from "hooks/useStateWithLocalstorage";
import EditableText from "./EditableText";

export default function Node({
  path,
  data,
  children,
  footnotes,
  setActiveNodePath,
  activeNodePath,

  expansions,
  setExpansions,

  notes,
  updateNote,
  setActiveNotebox,

  parentEditing,
}) {
  const ref = useRef();
  const noteRef = useRef();
  const [editing, setEditing] = useState(false);

  // const [backupNotes, setBackupNotes] =
  //   useStateWithLocalstorage("treebooks:notes");
  // useEffect(() => {
  //   console.log({ notes });
  //   setBackupNotes(notes);
  // }, [notes]);

  // const [expanded, setExpanded] = useState(false);
  const expanded = expansions.includes(path);

  const level = path.split(".").length;

  // const isActiveNode = activeNodePath === path;
  useEffect(() => {
    if (activeNodePath === path) {
      ref.current?.scrollIntoView({ behavior: "auto", block: "start" });
      setActiveNotebox(noteRef.current);
      // noteRef.current?.focus();
    }
  }, [activeNodePath]);

  return (
    <div className={`relative`}>
      {((children && children.length > 0) || (footnotes && footnotes.length)) &&
        !parentEditing && (
          <button
            className="absolute text-gray-500 hover:text-black pt-3 pb-3 pr-2 pl-2"
            style={{ left: "-1.5rem" }}
            onClick={() => {
              // updateNode({ id, expanded: !expanded });
              // setExpanded(!expanded);
              // setActiveNodePath(path);
              if (expanded) {
                setExpansions(without(expansions, path));
              } else {
                setExpansions([...expansions, path]);
              }
            }}
          >
            {expanded ? "▼" : "►"}
          </button>
        )}
      <div className="md:flex">
        {/*transition-all duration-500 ease-in-out*/}
        <div
          ref={ref}
          className={`flex-1 prose dark:prose-dark lg:prose-xl rounded ${
            path === activeNodePath ? "bg-yellow-200 dark:bg-red-900" : ""
          } overflow-hidden ${parentEditing ? "h-0" : "px-2 py-2"}`}
          onClick={() => {
            setActiveNodePath(path);
          }}
        >
          {children && children.length > 0 ? (
            React.createElement(`h${level}`, {}, data)
          ) : (
            <ReactMarkdown children={data} />
          )}
        </div>

        <EditableText
          placeholder={"..."}
          ref={noteRef}
          className={`flex-1 border ${
            notes[path]?.body
              ? "bg-blue-100 dark:bg-gray-700 border-blue-200 dark:border-gray-600"
              : "dark:bg-gray-800 border-transparent"
          } ${
            parentEditing && !notes[path]?.body ? "hidden" : ""
          } focus:bg-blue-200 dark:focus:bg-gray-600 italic w-full focus:outline-none focus:ring  font-mono rounded px-2 py-2 md:ml-2 md:max-w-md lg:max-w-xl resize-none dark:text-white`}
          value={notes[path]?.body}
          onChange={(body) => updateNote({ id: path, body })}
          onFocus={() => setEditing(true)}
          onBlur={() => setEditing(false)}
        />
      </div>
      {expanded && (children || footnotes) && (
        <div className="ml-4">
          {(children || footnotes)
            .sort((a, b) => a.id - b.id)
            .map((child, i) => (
              <Node
                key={`${path}.${i}`}
                path={`${path}.${i}`}
                {...child}
                setActiveNodePath={setActiveNodePath}
                activeNodePath={activeNodePath}
                expansions={expansions}
                setExpansions={setExpansions}
                notes={notes}
                updateNote={updateNote}
                setActiveNotebox={setActiveNotebox}
                parentEditing={parentEditing || editing}
              />
            ))}
        </div>
      )}
    </div>
  );
}
