import { useState, useEffect } from "react";
import get from "lodash.get";

import useMousetrap from "hooks/useMousetrap";
import useSpeechSynthesis from "hooks/useSpeechSynthesis";
import useStateWithLocalstorage from "hooks/useStateWithLocalstorage";

import { next, previous } from "treeTraversals";

import {
  PauseIcon,
  PlayIcon,
  RewindIcon,
  ForwardIcon,
  PlusIcon,
  MinusIcon,
} from "icons";

export default function Speaker({
  activeNodePath,
  setActiveNodePath,
  nodes,
  expansions,
  setExpansions,
}) {
  const [rate, setRate] = useStateWithLocalstorage("treebooks:rate", 1);
  const [playing, setPlaying] = useState(false);
  const [stoppedSpeakingAt, setStoppedSpeakingAt] = useState(null);

  const { supported, speak, speaking, cancel, voices } = useSpeechSynthesis();
  const voice = voices.find((v) => v.default);

  const activeNode = get(
    nodes?.children,
    activeNodePath?.split(".").join(".children.")
  );
  // console.log({ activeNode, activeNodePath, nodes });

  // onEnd but with state variables that are up-to-date
  // a little hacky but ¯\_(ツ)_/¯
  useEffect(() => {
    // console.info("stopped speaking");
    // if (!canceled) {
    // speaking ended without hitting "pause,"
    // so select the next node and then speak it
    // console.info("next node...");
    // TODO: implement next
    if (stoppedSpeakingAt)
      next({
        activeNodePath,
        setActiveNodePath,
        nodes,
        expansions,
        setExpansions,
      });
    // }
  }, [stoppedSpeakingAt]);

  function startReading() {
    cancel();
    setPlaying(true);
    const text = activeNode?.data.replaceAll(/[*>]/g, "") || "error";
    speak({
      voice,
      text,
      rate,
      onEnd: ({ canceled }) => {
        if (!canceled) setStoppedSpeakingAt(+new Date());
        else setPlaying(false);
      },
    });
  }

  function stopReading() {
    setPlaying(false);
    cancel();
  }

  function rateFaster() {
    setRate(Math.min(5, rate + 0.25));
  }
  function rateSlower() {
    setRate(Math.max(1, rate - 0.25));
  }

  useMousetrap({
    space: () => {
      if (speaking) stopReading();
      else startReading();
    },
    "+": rateFaster,
    "-": rateSlower,
  });

  useEffect(() => {
    if (speaking) {
      cancel();
      startReading();
    }
  }, [rate]);

  useEffect(() => {
    // console.log(
    //   `Speaker.js: activeNodePath changed. speaking=${speaking} and playing=${playing}`
    // );
    // did we change it?
    // ie: did we stop speaking but we're still playing?
    if (!speaking && playing) {
      startReading();
    } else if (speaking && playing) {
      // here be dragons
      cancel();
      // startReading();
    } else {
      cancel();
    }
  }, [activeNodePath]);

  return (
    <div className="fixed bottom-0 left-0 w-full bg-black bg-opacity-50 dark:bg-opacity-70 p-1 z-10 flex justify-between items-center">
      <div></div>
      <div className="flex justify-center items-center text-blue-300  dark:text-blue-500">
        <button>
          <RewindIcon className="h-10 w-10 hover:text-blue-400" />
        </button>
        <button
          className="hover:text-blue-400"
          onClick={speaking ? cancel : startReading}
        >
          {speaking ? (
            <PauseIcon className="h-20 w-20" />
          ) : (
            <PlayIcon className="h-20 w-20" />
          )}
        </button>
        <button>
          <ForwardIcon className="h-10 w-10 hover:text-blue-400" />
        </button>
      </div>

      <div className="flex justify-center items-center text-blue-400">
        <button onClick={rateSlower}>
          <MinusIcon className="h-10 w-10" />
        </button>
        <span className="text-2xl font-mono">{rate.toFixed(2)}</span>
        <button onClick={rateFaster}>
          <PlusIcon className="h-10 w-10" />
        </button>
      </div>
    </div>
  );
}
