import { useState } from "react";
import { useParams } from "react-router-dom";
import { useCollection, useDoc } from "../hooks/useFirebase";
import useFetch from "use-http";
import Node from "./Node";
import useMousetrap from "hooks/useMousetrap";

import { up, down, left, right } from "../treeTraversals";
import Speaker from "components/Speaker";

export default function Book() {
  let { bookId } = useParams();
  const { data: book, update: updateBook } = useDoc(`ebooks/${bookId}`);
  const { data: notesArray, update: updateNote } = useCollection(
    `ebooks/${bookId}/notes`
  );

  let notes = {};
  if (notesArray)
    notes = Object.fromEntries(notesArray.map((note) => [note.id, note]));

  const { loading, error, data } = useFetch(book?.src, {}, [book?.src]);

  // const [activeNodePath, setActiveNodePath] = useState(null);
  function setActiveNodePath(activeNodePath) {
    updateBook({ activeNodePath });
  }
  const activeNodePath = book?.activeNodePath;

  // const [expansions, setExpansions] = useState([]);
  function setExpansions(expansions) {
    updateBook({ expansions });
  }
  const expansions = book?.expansions || [];

  const [activeNotebox, setActiveNotebox] = useState(null);

  const params = {
    activeNodePath,
    setActiveNodePath,
    nodes: data,
    expansions,
    setExpansions,
  };
  useMousetrap({
    up: () => up(params),
    k: () => up(params),
    down: () => down(params),
    j: () => down(params),
    left: () => left(params),
    h: () => left(params),
    right: () => right(params),
    l: () => right(params),
    ".": () => activeNotebox.focus(),
  });

  return (
    <div className="mb-32">
      <h1 className="text-5xl text-gray-900 dark:text-white sm:text-6xl sm:tracking-tight lg:text-7xl">
        <span className="font-extrabold">
          {book?.metadata?.title || "Loading..."}
        </span>{" "}
        <span>{book?.metadata?.subtitle}</span>
      </h1>
      <pre className="hidden fixed bottom-0 right-0 p-3 bg-black text-green-500 z-10 overflow-y-scroll max-h-screen mb-20">
        {JSON.stringify({ activeNodePath, expansions }, null, 2)}
      </pre>
      <Speaker
        activeNodePath={activeNodePath}
        setActiveNodePath={setActiveNodePath}
        nodes={data}
        expansions={expansions}
        setExpansions={setExpansions}
      />
      {data?.children &&
        data.children.map((node, i) => (
          <Node
            key={`${i}`}
            {...node}
            path={`${i}`}
            setActiveNodePath={setActiveNodePath}
            activeNodePath={activeNodePath}
            expansions={expansions}
            setExpansions={setExpansions}
            notes={notes}
            updateNote={updateNote}
            setActiveNotebox={setActiveNotebox}
          />
        ))}
    </div>
  );
}
