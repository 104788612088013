import { useEffect, useState } from "react";

export default function useSpeechSynthesis(props = {}) {
  // const { onEnd = () => {} } = props;
  const [voices, setVoices] = useState([]);
  const [speaking, setSpeaking] = useState(false);
  // const [canceled, setCanceled] = useState(false);
  const [supported, setSupported] = useState(false);
  const [utterance, setUtterance] = useState(null);

  const processVoices = voiceOptions => {
    setVoices(voiceOptions);
  };

  const getVoices = () => {
    // Firefox seems to have voices upfront and never calls the
    // voiceschanged event
    let voiceOptions = window.speechSynthesis.getVoices();
    if (voiceOptions.length > 0) {
      processVoices(voiceOptions);
      return;
    }

    window.speechSynthesis.onvoiceschanged = event => {
      voiceOptions = event.target.getVoices();
      processVoices(voiceOptions);
    };
  };

  // const handleEnd = () => {
  //   setSpeaking(false);
  //   onEnd();
  // };

  useEffect(() => {
    if (typeof window !== "undefined" && window.speechSynthesis) {
      setSupported(true);
      getVoices();
    }
  }, []);

  const speak = (args = {}) => {
    const {
      voice = null,
      text = "",
      rate = 1,
      pitch = 1,
      volume = 1,
      onEnd = () => {},
    } = args;
    if (!supported) return;
    setSpeaking(true);

    window._useSpeechSynthesisCanceled = false;
    // Firefox won't repeat an utterance that has been
    // spoken, so we need to create a new instance each time
    const utterance = new window.SpeechSynthesisUtterance();
    utterance.text = text;
    // utterance.text =
    //   '<?xml version="1.0"?>\r\n<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US"><emphasis level="strong">Welcome</emphasis> to the Bird Seed Emporium.  Welcome to the Bird Seed Emporium.</speak>';

    utterance.voice = voice;
    utterance.onend = e => {
      // console.log(e);
      setSpeaking(false);
      onEnd({ canceled: window._useSpeechSynthesisCanceled });
    };
    utterance.rate = rate;
    utterance.pitch = pitch;
    utterance.volume = volume;
    window.speechSynthesis.speak(utterance);
  };

  const cancel = () => {
    if (!supported) return;
    // console.log("canceled");
    // setCanceled(true);
    window._useSpeechSynthesisCanceled = true;
    setSpeaking(false);
    window.speechSynthesis.cancel();
  };

  return {
    supported,
    speak,
    speaking,
    cancel,
    voices,
  };
}
